import Vue from 'vue'
import axios from '@axios'

export default Vue.extend({
  data() {
    return {
      Role: '',
      Permission: [],
      ShipmentDetail: {
        IsArchive: false,
      },
    }
  },
  beforeMount() { this.UserPermission() },
  mounted() { },
  methods: {
    LoadShipmentDetail() {
      return axios.get(`/booking/shipment/${this.params.data.shipmentId}/detail`)
        .then(resp => {
          const response = resp.data
          const respdata = response

          this.ShipmentDetail.IsArchive = respdata.isArchive

          // Get user role
          this.Role = this.GetUserRole()
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
        })
    },
    UserPermission() {
      this.LoadShipmentDetail()
    },
    Clickhandler() {
      let returnID = ''
      if (this.params.data.containerId) returnID = this.params.data.containerId

      this.params.context.componentParent.Open(returnID)
    },
    Deletehandler() {
      let returnID = ''
      if (this.params.data.containerId) returnID = this.params.data.containerId

      this.params.context.componentParent.Delete(returnID)
    },
  },
  template: `
        <span>
            <b-button
            v-b-tooltip.hover="'View'"
            size="sm" 
            @click="Clickhandler()" 
            variant="primary" 
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon"
            >
            <font-awesome-icon
              :icon="['fas', 'eye']"
              size="lg"
            />
            </b-button>
        </span>
    `,
})
