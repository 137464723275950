<template>
  <div>
    <b-card>
      <AgGrid
        ref="ShipmentDetailContainerGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-if="(!ShipmentDetail.IsArchive && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Add new containers'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="MassUpdateContainer()"
          ><font-awesome-icon
             :icon="['far', 'edit']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Mass Update</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="MassUpdateModal"
      ref="MassUpdate"
      title="Mass Update Container"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="MassUpdateRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Last Free Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Last Free Date:</b></i></label>
                  <b-form-datepicker
                    v-model="MassUpdate.LastFreeDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Empty Dispatch Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Empty Dispatch Date:</b></i></label>
                  <b-form-datepicker
                    v-model="MassUpdate.DispatchDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Gate In Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Gate In Date:</b></i></label>
                  <b-form-datepicker
                    v-model="MassUpdate.InGateDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Gate Out Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Gate Out Date:</b></i></label>
                  <b-form-datepicker
                    v-model="MassUpdate.OutGateDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Discharge Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Discharge Date:</b></i></label>
                  <b-form-datepicker
                    v-model="MassUpdate.DischargedDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Appointment Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Appointment Date:</b></i></label>
                  <b-form-datepicker
                    v-model="MassUpdate.AppointmentDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Delivery Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Delivery Date:</b></i></label>
                  <b-form-datepicker
                    v-model="MassUpdate.DeliveredDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Empty Return Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Empty Return Date:</b></i></label>
                  <b-form-datepicker
                    v-model="MassUpdate.EmptyReturnDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="MassUpdateSave()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="UpdateContainerModal"
      ref="UpdateContainer"
      title="Update Container"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="UpdateContainerRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Container Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Container Type:</b></i></label>
                  <v-select
                    v-model="container.ContainerSize"
                    :options="ContainerTypeOption"
                    label="text"
                    placeholder="Select the type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Container Number"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Container Number:</b></i></label>
                  <b-form-input
                    v-model="container.ContainerNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Container Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Per Diem Free Time (days)"
                rules="positive|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Per Diem Free Time (days):</b></i></label>
                  <b-form-input
                    v-model="container.PerdiemFreeTime"
                    type="number"
                    min="0"
                    step="1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Last Free Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Last Free Date:</b></i></label>
                  <b-form-datepicker
                    v-model="container.LastFreeDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Dispatch Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Empty Dispatch Date:</b></i></label>
                  <b-form-datepicker
                    v-model="container.DispatchDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Gate In Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Gate In Date:</b></i></label>
                  <b-form-datepicker
                    v-model="container.InGateDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Gate Out Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Gate Out Date:</b></i></label>
                  <b-form-datepicker
                    v-model="container.OutGateDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    @input="PerDIEM()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Discharge Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Discharge Date:</b></i></label>
                  <b-form-datepicker
                    v-model="container.DischargedDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Appointment Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Appointment Date:</b></i></label>
                  <b-form-datepicker
                    v-model="container.AppointmentDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Delivery Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Delivery Date:</b></i></label>
                  <b-form-datepicker
                    v-model="container.DeliveredDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Empty Return Date"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Empty Return Date:</b></i></label>
                  <b-form-datepicker
                    v-model="container.EmptyReturnDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    @input="PerDIEM()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="CBM"
                rules="required|min_value:0"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>CBM:</b></i></label>
                  <b-form-input
                    v-model="container.CBM"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Weight (KG)"
                rules="required|min_value:0"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Weight (KG):</b></i></label>
                  <b-form-input
                    v-model="container.Weight"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Pallet"
                rules="min_value:0|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Pallet:</b></i></label>
                  <b-form-input
                    v-model="container.Pallet"
                    type="number"
                    min="0"
                    step="1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="CTN"
                rules="min_value:0|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>CTN:</b></i></label>
                  <b-form-input
                    v-model="container.CTN"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="PCS"
                rules="min_value:0|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>PCS:</b></i></label>
                  <b-form-input
                    v-model="container.PCS"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="Role != 'customer'"
            md="6"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Ocean Freight Cost"
                rules="required|min_value:0|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Ocean Freight Cost:</b></i></label>
                  <b-form-input
                    v-model="container.OceanFreightCost"
                    type="number"
                    min="0"
                    step="0.01"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Ocean Freight Sell Rate"
                rules="required|min_value:0|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Ocean Freight Sell Rate:</b></i></label>
                  <b-form-input
                    v-model="container.OceanFreightSellRate"
                    type="number"
                    min="0"
                    step="0.01"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="Role === 'manager' || Role === 'company admin'"
            md="6"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Origin Sell Rate"
                rules="min_value:0|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Origin Sell Rate:</b></i></label>
                  <b-form-input
                    v-model="container.OriginSellRate"
                    type="number"
                    min="0"
                    step="0.01"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="divider my-2">
          <div class="divider-text">
            {{ 'Last Updated: ' + container.UpdatedOn + ' by ' + container.UpdatedBy }}
          </div>
        </div>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="UpdateContainer()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/Booking/BookingShipmentContainerBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  name: 'ShipmentContainer',
  components: {
    AgGrid,
  },
  props: {
    shipmentId: { type: String, default: '' },
    detentionDay: { type: Number, default: 0 },
  },
  data() {
    return {
      required,
      TempLineId: '',
      aggrid: [],
      ContainerTypeOption: [],
      AttachedBookingOption: [],
      container: {
        ContainerId: '',
        ContainerSize: '',
        ContainerNumber: '',
        AttachedBookingList: '',
        DischargedDate: '',
        DispatchDate: '',
        InGateDate: '',
        OutGateDate: '',
        AppointmentDate: '',
        DeliveredDate: '',
        EmptyReturnDate: '',
        PerdiemFreeTime: 0,
        LastFreeDate: '',
        OceanFreightCost: 0.0,
        OceanFreightSellRate: 0.0,
        OriginSellRate: 0,
        CBM: 0,
        Weight: 0,
        Pallet: 0,
        PCS: 0,
        CTN: 0,
        UpdatedBy: '',
        UpdatedOn: '',
      },
      Role: '',
      Permission: [],
      ShipmentDetail: {
        BookingIdList: '',
        IsArchive: false,
        Weight: 0,
        Pallet: 0,
        CBM: 0,
        CTN: 0,
        PCS: 0,
      },
      MassUpdate: {
        LastFreeDate: '',
        DischargedDate: '',
        DispatchDate: '',
        InGateDate: '',
        OutGateDate: '',
        AppointmentDate: '',
        DeliveredDate: '',
        EmptyReturnDate: '',
      },

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    // Get user role
    this.Role = this.GetUserRole()
    this.aggrid = this.InitGridInstance('ShipmentDetailContainer')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'id',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      {
        headerName: 'Container Number',
        field: 'containerNumber',
        minWidth: 135,
        cellRenderer: params => (new Date(params.data.lastFreeDate).toDateString() === new Date().toDateString() || this.DEM(params.data.lastFreeDate) > 0 ? `<span style="color:red">${params.value}</span>` : `${params.value || ''}`),
      },
      { headerName: 'Type', field: 'containerSize', minWidth: 100 },
      { headerName: 'Per Diem', field: 'perdiemFreeTime', minWidth: 75 },
      {
        headerName: 'DEM',
        field: 'lastFreeDate',
        minWidth: 75,
        cellRenderer: params => (this.DEM(params.value) < 0 ? `<span style="color:green">${this.DEM(params.value)}</span>` : `<span style="color:purple">${this.DEM(params.value)}</span>`),
      },
      {
        headerName: 'LFD',
        field: 'lastFreeDate',
        minWidth: 100,
        cellRenderer: params => (new Date(params.value).toDateString() >= new Date().toDateString() ? `<span style="color:red">${params.value || ''}</span>` : `<span style="color:green">${params.value || ''}</span>`),
      },
      { headerName: 'Empty Dispatch', field: 'dispatchDate', minWidth: 100 },
      { headerName: 'Gate In', field: 'inGateDate', minWidth: 100 },
      { headerName: 'Gate Out', field: 'outGateDate', minWidth: 100 },
      { headerName: 'Discharge', field: 'dischargedDate', minWidth: 100 },
      { headerName: 'Appointment', field: 'appointmentDate', minWidth: 100 },
      { headerName: 'Delivered', field: 'deliveredDate', minWidth: 100 },
      { headerName: 'Empty Returned', field: 'emptyReturnDate', minWidth: 100 },
      { headerName: 'CBM', field: 'cbm', minWidth: 100 },
      { headerName: 'Weight (KG)', field: 'weight', minWidth: 100 },
      { headerName: 'Pallet', field: 'pallet', minWidth: 100 },
      { headerName: 'CTN', field: 'ctn', minWidth: 100 },
      { headerName: 'PCS', field: 'pcs', minWidth: 100 },

    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ShipmentDetailContainerGrid.saveState('ShipmentDetailContainer') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ShipmentDetailContainerGrid.saveState('ShipmentDetailContainer') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ShipmentDetailContainerGrid.saveState('ShipmentDetailContainer') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ShipmentDetailContainerGrid.saveState('ShipmentDetailContainer') }

    if (this.Role !== 'customer') this.aggrid.columnDefs.push({ headerName: 'Ocean Freight Cost', field: 'oceanFreightCost', minWidth: 100 })
    if (this.Role === 'manager' || this.Role === 'company admin') this.aggrid.columnDefs.push({ headerName: 'Origin Sell Rate', field: 'originSellRate', minWidth: 100 })
    this.aggrid.columnDefs.push({ headerName: 'Ocean Freight Sell Rate', field: 'oceanFreightSellRate', minWidth: 100 }, {
      headerName: 'Updated By', field: 'updatedBy', maxWidth: 134, minWidth: 100,
    },
    {
      headerName: 'Updated On',
      field: 'updatedOn',
      maxWidth: 158,
      minWidth: 100,
      enableRowGroup: false,
      enablePivot: false,
    })

    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(ContainerId) {
      this.$refs.UpdateContainer.show()
      Object.assign(this.$data.container, this.$options.data.apply(this))
      this.LoadContainer(ContainerId)
    },
    DoubleClickOpenPopup(params) {
      this.$refs.UpdateContainer.show()
      Object.assign(this.$data.container, this.$options.data.apply(this))
      this.LoadContainer(params.data.containerId)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadContainers(), this.LoadContainerCodes(), this.LoadShipmentDetail()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadShipmentDetail() {
      return axios.get(`/booking/shipment/${this.shipmentId}/detail`)
        .then(resp => {
          const response = resp.data
          const respdata = response

          this.ShipmentDetail.BookingIdList = respdata.bookingIdList
          this.ShipmentDetail.IsArchive = respdata.isArchive
          this.ShipmentDetail.Weight = respdata.weight
          this.ShipmentDetail.Pallet = respdata.pallet
          this.ShipmentDetail.CBM = respdata.cbm
          this.ShipmentDetail.CTN = respdata.ctn
          this.ShipmentDetail.PCS = respdata.pcs
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
        })
    },
    LoadContainerCodes() {
      // Loading Customer List
      return axios.get('/booking/codes/containersize')
        .then(rowData => {
          this.ContainerTypeOption = rowData.data
        })
    },
    LoadContainers() {
      return axios.get(`/booking/shipment/${this.shipmentId}/containers`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ShipmentDetailContainerGrid.ResetColumns()
        })
    },
    LoadContainer(ContainerId) {
      axios.get(`/booking/shipment/${this.shipmentId}/containers/${ContainerId}`).then(resp => {
        this.LoadingStart()
        const response = resp.data
        const respdata = response
        this.container.ContainerId = respdata.containerId
        this.container.ContainerSize = this.ContainerTypeOption.find(option => option.containerCode === respdata.containerSize)
        this.container.ContainerNumber = respdata.containerNumber
        this.container.DispatchDate = new Date(respdata.dispatchDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.dispatchDate) : null
        this.container.DischargedDate = new Date(respdata.dischargedDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.dischargedDate) : null
        this.container.InGateDate = new Date(respdata.inGateDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.inGateDate) : null
        this.container.OutGateDate = new Date(respdata.outGateDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.outGateDate) : null
        this.container.AppointmentDate = new Date(respdata.appointmentDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.appointmentDate) : null
        this.container.DeliveredDate = new Date(respdata.deliveredDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.deliveredDate) : null
        this.container.EmptyReturnDate = new Date(respdata.emptyReturnDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.emptyReturnDate) : null
        this.container.PerdiemFreeTime = respdata.perdiemFreeTime
        this.container.LastFreeDate = new Date(respdata.lastFreeDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.lastFreeDate) : null
        this.container.OceanFreightCost = respdata.oceanFreightCost
        this.container.OceanFreightSellRate = respdata.oceanFreightSellRate
        this.container.OriginSellRate = respdata.originSellRate
        this.container.CBM = respdata.cbm
        this.container.Weight = respdata.weight
        this.container.Pallet = respdata.pallet
        this.container.PCS = respdata.pcs
        this.container.CTN = respdata.ctn
        this.container.UpdatedBy = respdata.updatedBy
        this.container.UpdatedOn = respdata.updatedOn
        this.LoadingEnd()
      })
    },
    UpdateContainer() {
      if (this.ShipmentDetail.IsArchive) {
        this.DisplayError('Container cannot be updated.', 'Shipment has been archived.')
      } else {
        this.$refs.UpdateContainerRules.validate().then(success => {
          if (success) {
            this.LoadingStart()
            const ShipmentContainersModel = {
              ContainerSize: this.container.ContainerSize.containerCode,
              ContainerNumber: this.container.ContainerNumber,

              LastFreeDate: this.CheckNull(this.container.LastFreeDate) ? new Date(this.container.LastFreeDate).toJSON().split('T')[0] : null,
              DischargedDate: this.CheckNull(this.container.DischargedDate) ? new Date(this.container.DischargedDate).toJSON().split('T')[0] : null,
              DispatchDate: this.CheckNull(this.container.DispatchDate) ? new Date(this.container.DispatchDate).toJSON().split('T')[0] : null,
              InGateDate: this.CheckNull(this.container.InGateDate) ? new Date(this.container.InGateDate).toJSON().split('T')[0] : null,
              OutGateDate: this.CheckNull(this.container.OutGateDate) ? new Date(this.container.OutGateDate).toJSON().split('T')[0] : null,
              AppointmentDate: this.CheckNull(this.container.AppointmentDate) ? new Date(this.container.AppointmentDate).toJSON().split('T')[0] : null,
              DeliveredDate: this.CheckNull(this.container.DeliveredDate) ? new Date(this.container.DeliveredDate).toJSON().split('T')[0] : null,
              EmptyReturnDate: this.CheckNull(this.container.EmptyReturnDate) ? new Date(this.container.EmptyReturnDate).toJSON().split('T')[0] : null,
              PerdiemFreeTime: this.container.PerdiemFreeTime,
              OceanFreightCost: this.container.OceanFreightCost,
              OceanFreightSellRate: this.container.OceanFreightSellRate,
              OriginSellRate: this.container.OriginSellRate,
              CBM: this.container.CBM,
              Weight: this.container.Weight,
              Pallet: this.container.Pallet,
              PCS: this.container.PCS,
              CTN: this.container.CTN,
            }
            axios.put(`/booking/shipment/${this.shipmentId}/containers/${this.container.ContainerId}`, ShipmentContainersModel).then(() => {
              this.LoadingEnd()
              this.$refs.UpdateContainer.hide()
              this.DisplaySuccess('Container data has been updated.').then(() => {
                this.LoadContainers()
                this.LoadShipmentDetail().then(() => {
                  const returnvalue = {
                    CBM: this.ShipmentDetail.CBM,
                    Weight: this.ShipmentDetail.Weight,
                    Pallet: this.ShipmentDetail.Pallet,
                    CTN: this.ShipmentDetail.CTN,
                    PCS: this.ShipmentDetail.PCS,
                  }
                  // return to parent
                  this.$emit('LoadChildWeightCBM', returnvalue)
                })
              })
            })
              .catch(e => {
                this.LoadingEnd()
                this.$refs.UpdateContainer.hide()
                this.DisplayError('Container cannot be updated.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
              })
          } else {
            this.DisplayError('Container cannot be updated.', 'Please check data requirements.')
          }
        })
      }
    },
    MassUpdateContainer() {
      Object.assign(this.$data.MassUpdate, this.$options.data.apply(this))
      this.$refs.MassUpdate.show()
    },
    MassUpdateSave() {
      if (this.ShipmentDetail.IsArchive) {
        this.DisplayError('Container cannot be updated.', 'Shipment has been archived.')
      } else {
        this.$refs.MassUpdateRules.validate().then(success => {
          if (success) {
            this.LoadingStart()

            const ShipmentContainersModel = {
              LastFreeDate: this.CheckNull(this.MassUpdate.LastFreeDate) ? new Date(this.MassUpdate.LastFreeDate).toJSON().split('T')[0] : null,
              DischargedDate: this.CheckNull(this.MassUpdate.DischargedDate) ? new Date(this.MassUpdate.DischargedDate).toJSON().split('T')[0] : null,
              DispatchDate: this.CheckNull(this.MassUpdate.DispatchDate) ? new Date(this.MassUpdate.DispatchDate).toJSON().split('T')[0] : null,
              InGateDate: this.CheckNull(this.MassUpdate.InGateDate) ? new Date(this.MassUpdate.InGateDate).toJSON().split('T')[0] : null,
              OutGateDate: this.CheckNull(this.MassUpdate.OutGateDate) ? new Date(this.MassUpdate.OutGateDate).toJSON().split('T')[0] : null,
              AppointmentDate: this.CheckNull(this.MassUpdate.AppointmentDate) ? new Date(this.MassUpdate.AppointmentDate).toJSON().split('T')[0] : null,
              DeliveredDate: this.CheckNull(this.MassUpdate.DeliveredDate) ? new Date(this.MassUpdate.DeliveredDate).toJSON().split('T')[0] : null,
              EmptyReturnDate: this.CheckNull(this.MassUpdate.EmptyReturnDate) ? new Date(this.MassUpdate.EmptyReturnDate).toJSON().split('T')[0] : null,
            }
            axios.put(`/booking/shipment/${this.shipmentId}/containers/massupdate`, ShipmentContainersModel).then(() => {
              this.LoadingEnd()
              this.$refs.MassUpdate.hide()
              this.DisplaySuccess('Container data has been updated.').then(() => {
                this.LoadContainers()
              })
            })
              .catch(e => {
                this.LoadingEnd()
                this.$refs.MassUpdate.hide()
                this.DisplayError('Containers cannot be updated.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
              })
          } else {
            this.DisplayError('Containers cannot be updated.', 'Please check data requirements.')
          }
        })
      }
    },
    DEM(lfd) {
      try {
        const today = new Date()
        const date2 = new Date(lfd)
        if (date2.toJSON().split('T')[0] !== '1970-01-01') { // true = not null
          const dtime = today.getTime() - date2.getTime()
          return Math.round((dtime / (1000 * 3600 * 24)) - 1)
        }
        return 0
      } catch (e) {
        return 0
      }
    },
    PerDIEM() {
      try {
        const empty = new Date(this.container.EmptyReturnDate)
        const gateout = new Date(this.container.OutGateDate)
        if (empty.toJSON().split('T')[0] !== '1970-01-01' && gateout.toJSON().split('T')[0] !== '1970-01-01') { // true = not null
          const dtime = empty.getTime() - gateout.getTime()
          this.container.PerdiemFreeTime = Math.round((dtime / (1000 * 3600 * 24)) - this.detentionDay - 1)
        } else {
          this.container.PerdiemFreeTime = 0
        }
      } catch (e) {
        this.container.PerdiemFreeTime = 0
      }
    },
  },
}
</script>
