import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      Role: '',
      Permission: [],
      ShipmentDetail: {
        IsNew: false,
        IsPendingReview: false,
        IsApproved: false,
        IsDenied: false,
        IsArchive: false,
        IsArrived: false,
        IsInTransit: false,
        IsPendingDepart: false,
        IsPendingLoading: false,
        IsPendingSO: false,
      },
    }
  },
  beforeMount() {
    this.UserPermission()
  },
  mounted() { },
  methods: {
    UserPermission() {
      // Get user role
      this.Role = this.GetUserRole()
      // Get permission
      this.Permission = this.GetUserPermission('Booking')
    },
    Clickhandler() {
      let returnID = ''
      let filename = ''
      if (this.params.data.id) {
        returnID = this.params.data.id
        filename = this.params.data.docName
      }
      this.params.context.componentParent.Download(returnID, filename)
    },
    Deletehandler() {
      let returnID = ''
      if (this.params.data.id) returnID = this.params.data.id

      this.params.context.componentParent.Delete(returnID)
    },
  },
  template: `
        <span>
            <b-button
            size="sm" 
            @click="Clickhandler()" 
            variant="primary" 
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon"
            >
            <font-awesome-icon
              :icon="['fas', 'file-download']"
              size="lg"
            />
            </b-button>
            <b-button
            v-if="((Role != 'readonly' && Role != 'readonly admin') && (Permission.EditShipment)) || (Role == 'company admin')"
            size="sm" 
            @click="Deletehandler()" 
            variant="danger" 
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon"
            >
            <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              size="lg"
            />
            </b-button>
        </span>
    `,
})
