<template>
  <div>
    <b-card>
      <b-button-toolbar justify>
        <div>
          <b-button
            v-if="(!ShipmentDetail.IsArchive && (ShipmentDetail.IsNew) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.DeleteShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Delete in Database'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="btn-icon mb-1"
            @click="DeleteShipment()"
          >
            <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              size="lg"
            />
          </b-button>&nbsp;
          <b-button
            v-if="((Role != 'readonly' && Role != 'readonly admin') && (!ShipmentDetail.IsArchive)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Archive Shipment'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mb-1"
            @click="ArchiveShipment()"
          ><font-awesome-icon
             :icon="['fas', 'archive']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Archive</span></b-button>&nbsp;
          <b-button
            v-if="((Role != 'readonly' && Role != 'readonly admin') && (ShipmentDetail.IsArchive)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Unarchive Shipment'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mb-1"
            @click="UnArchiveShipment()"
          ><font-awesome-icon
             :icon="['fas', 'archive']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Unarchive</span></b-button>&nbsp;
          <b-button
            v-b-tooltip.hover="'Print'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="Print()"
          >
            <font-awesome-icon
              :icon="['fas', 'print']"
              size="lg"
            />
          </b-button>&nbsp;
          <b-button
            v-b-tooltip.hover="'Refresh'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="LoadAll()"
          >
            <font-awesome-icon
              :icon="['fas', 'sync']"
              size="lg"
            />
          </b-button>&nbsp;
        </div>
        <div>
          <b-button
            v-if="(!ShipmentDetail.IsArchive && (!ShipmentDetail.IsPendingReview && !ShipmentDetail.IsApproved) && (!ShipmentDetail.IsComplete || ShipmentDetail.IsNew) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Submit Shipment'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SubmitShipment()"
          ><font-awesome-icon
             :icon="['far', 'paper-plane']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Submit</span></b-button>&nbsp;
          <b-button
            v-if="(!ShipmentDetail.IsArchive && (ShipmentDetail.IsPendingReview && !ShipmentDetail.IsApproved) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Withdraw Shipment'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mb-1"
            @click="WithdrawShipment()"
          ><font-awesome-icon
             :icon="['fas', 'backspace']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Withdraw</span></b-button>&nbsp;
          <b-button
            v-if="(!ShipmentDetail.IsArchive && (ShipmentDetail.IsPendingReview && !ShipmentDetail.IsApproved && !ShipmentDetail.IsAttached) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.ApproveShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Approve Shipment'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-1"
            @click="ApproveShipment()"
          ><font-awesome-icon
             :icon="['far', 'check-circle']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Approve</span></b-button>&nbsp;
          <b-button
            v-if="(!ShipmentDetail.IsArchive && (((!ShipmentDetail.IsPendingReview && ShipmentDetail.IsApproved) || (ShipmentDetail.IsPendingReview && !ShipmentDetail.IsApproved) ) && !ShipmentDetail.IsAttached) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.ApproveShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Deny Shipment'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mb-1"
            @click="DenyShipment()"
          ><font-awesome-icon
             :icon="['far', 'times-circle']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Deny</span></b-button>&nbsp;
          <b-button
            v-if="(ShipmentDetail.IsApproved && (Role != 'readonly' && Role != 'readonly admin') && (!ShipmentDetail.IsArchive) && (Permission.EditShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Pending SO'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="PendingSO()"
          ><font-awesome-icon
             :icon="['fas', 'file']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Pending SO</span></b-button>&nbsp;
          <b-button
            v-if="(ShipmentDetail.IsPendingSO && (Role != 'readonly' && Role != 'readonly admin') && (!ShipmentDetail.IsArchive) && (Permission.EditShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Pending Loading'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="PendingLoading()"
          ><font-awesome-icon
             :icon="['fas', 'dolly-flatbed']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Pending Loading</span></b-button>&nbsp;
          <b-button
            v-if="(ShipmentDetail.IsPendingLoading && (Role != 'readonly' && Role != 'readonly admin') && (!ShipmentDetail.IsArchive) && (Permission.EditShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Pending Depart'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="PendingDepart()"
          ><font-awesome-icon
             :icon="['fas', 'plane-departure']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Pending Depart</span></b-button>&nbsp;
          <b-button
            v-if="(ShipmentDetail.IsPendingDepart && (Role != 'readonly' && Role != 'readonly admin') && (!ShipmentDetail.IsArchive) && (Permission.EditShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Shipment Depart'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="Depart()"
          ><font-awesome-icon
             :icon="['fas', 'plane-departure']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Depart</span></b-button>&nbsp;
          <b-button
            v-if="(ShipmentDetail.IsInTransit && (Role != 'readonly' && Role != 'readonly admin') && (!ShipmentDetail.IsArchive) && (Permission.EditShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Shipment Arrived'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="Arrived()"
          ><font-awesome-icon
             :icon="['fas', 'plane-arrival']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Arrived</span></b-button>&nbsp;
          <b-button
            v-if="(ShipmentDetail.IsArrived && (Role != 'readonly' && Role != 'readonly admin') && (!ShipmentDetail.IsArchive) && (Permission.EditShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Complete'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="Complete()"
          ><font-awesome-icon
             :icon="['fas', 'check']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Complete</span></b-button>&nbsp;
        </div>
      </b-button-toolbar>
      <div class="divider my-2">
        <div class="divider-text">
          {{ 'Detail - Approval Date: ' + (CheckNull(ShipmentDetail.ApproveDate) ? new Date(ShipmentDetail.ApproveDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(ShipmentDetail.ApproveDate).toLocaleDateString('en-US') : 'N/A' : 'N/A') }}
        </div>
      </div>
      <b-row>
        <b-col md="12">
          <validation-observer
            ref="DetailRules"
            tag="form"
          >
            <b-button-toolbar justify>
              <div style="width:75%">
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="MBL #"
                        rules="max:50"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class="required"><i><b>MBL #:</b></i></label>
                          <b-form-input
                            id="MBL"
                            v-model="ShipmentDetail.MBL"
                            v-uppercase
                            :state="errors.length > 0 ? false:null"
                            placeholder="MBL #"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="2"
                  >
                    <label><i><b>DG:</b></i></label>
                    <b-form-checkbox v-model="ShipmentDetail.IsDG" />
                  </b-col>
                </b-row>

              </div>
              <div>
                <b-button
                  v-if="(!ShipmentDetail.IsArchive && !ShipmentDetail.IsPendingReview && !ShipmentDetail.IsApproved && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditShipment)) || (Role == 'company admin')"
                  v-b-tooltip.hover="'Save'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mb-1"
                  @click="UpdateDetail()"
                >
                  <font-awesome-icon
                    :icon="['far', 'save']"
                    size="lg"
                  />
                </b-button>
              </div>
            </b-button-toolbar>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Attached HBL/Booking"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Attached HBL/Booking:</b></i> (please attach HBL/Booking and save before proceeding)</label>
                      <v-select
                        id="BookingIdList"
                        v-model="ShipmentDetail.BookingIdList"
                        :options="BookingIdListOptions"
                        label="text"
                        multiple
                        placeholder="Select a booking"
                        @input="onChangeSetBookingDetail"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Shipment Mode"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Shipment Mode:</b></i></label>
                      <v-select
                        id="ShipmentMode"
                        v-model="ShipmentDetail.ShipmentMode"
                        :options="ShipmentModeOptions"
                        label="text"
                        placeholder="Select a mode"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Service Type"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Service Type:</b></i></label>
                      <v-select
                        id="ShipmentType"
                        v-model="ShipmentDetail.ShipmentType"
                        :options="ShipmentTypeOptions"
                        label="text"
                        placeholder="Select a type"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Service Mode"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Service Mode:</b></i></label>
                      <v-select
                        id="ServiceType"
                        v-model="ShipmentDetail.ServiceType"
                        :options="ServiceTypeOptions"
                        label="text"
                        placeholder="Select a type"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Incoterm"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Incoterm:</b></i></label>
                      <v-select
                        id="Incoterm"
                        v-model="ShipmentDetail.Incoterm"
                        :options="IncotermOptions"
                        label="text"
                        placeholder="Select a term"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Customer"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Customer:</b></i></label>
                      <v-select
                        id="CustomerOption"
                        v-model="ShipmentDetail.CustomerId"
                        :options="CustomerOptions"
                        label="name"
                        placeholder="Select a Customer"
                        @input="onChangeSetConsigneeSeller"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Consignee"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Consignee:</b></i></label>
                      <v-select
                        id="ConsigneeOption"
                        v-model="ShipmentDetail.ConsigneeId"
                        :options="ConsigneeOptions"
                        label="name"
                        placeholder="Select a Consignee"
                        :disabled="CheckNull(ShipmentDetail.CustomerId) ? false : true"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Shipper"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Shipper:</b></i></label>
                      <v-select
                        id="SellerOption"
                        v-model="ShipmentDetail.SellerId"
                        :options="SellerOptions"
                        label="name"
                        placeholder="Select a Shipper"
                        :disabled="CheckNull(ShipmentDetail.CustomerId) ? false : true"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <label class="required"><i><b>Total CBM:</b></i></label><br>
                <span>{{ ShipmentDetail.CBM }}</span>
              </b-col>
              <b-col md="3">
                <label class="required"><i><b>Total Weight (KG):</b></i></label><br>
                <span>{{ ShipmentDetail.Weight }}</span>
              </b-col>
              <b-col md="2">
                <label><i><b>Total Pallets:</b></i></label><br>
                <span>{{ ShipmentDetail.Pallet }}</span>
              </b-col>
              <b-col md="3">
                <label><i><b>Total Cartons:</b></i></label><br>
                <span>{{ ShipmentDetail.CTN }}</span>
              </b-col>
              <b-col md="2">
                <label><i><b>Total Pcs:</b></i></label><br>
                <span>{{ ShipmentDetail.PCS }}</span>
              </b-col>
            </b-row>
            <div class="divider my-2">
              <div class="divider-text">
                Shipping Details
              </div>
            </div>
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Carrier"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Carrier(SCAC):</b></i></label>
                      <v-select
                        v-model="ShipmentDetail.Carrier"
                        :options="CarrierOptions"
                        placeholder="Select a SCAC"
                        label="scac"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Terminal"
                    rules=""
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class=""><i><b>Destination Terminal:</b></i></label>
                      <v-select
                        v-model="ShipmentDetail.TerminalLocation"
                        :options="POLPODOptions"
                        label="location"
                        placeholder="Select a Location"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Obl/Telex Receive"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Obl/Telex Receive:</b></i></label>
                      <b-form-datepicker
                        v-model="ShipmentDetail.OblTelexRcvDate"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Detention Days"
                    rules="required|positive|integer"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Detention Days:</b></i></label>
                      <b-form-input
                        v-model="ShipmentDetail.DetentionDay"
                        type="number"
                        min="0"
                        step="1"
                        :state="errors.length > 0 ? false:null"
                        placeholder="0"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="POL"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>POL:</b></i></label>
                      <v-select
                        v-model="ShipmentDetail.POL"
                        :options="POLPODOptions"
                        label="location"
                        placeholder="Select a Location"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="SI Cutoff"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class=""><i><b>SI Cutoff:</b></i></label>
                      <b-form-datepicker
                        v-model="ShipmentDetail.SICutoffDate"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="CY Cutoff"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class=""><i><b>CY Cutoff:</b></i></label>
                      <b-form-datepicker
                        v-model="ShipmentDetail.CYCutoffDate"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="ETA First US Port"
                    rule="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label
                        id="ETAFirstPort"
                        class="required"
                      ><i><b>ETA First US Port: </b><u>{{ 'Delay: ' + ETAFirstPortDelay || '' }}</u></i><b-button
                        v-b-tooltip.hover="'View history'"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="history"
                        class="btn-icon"
                        size="sm"
                        @click="LoadHistory('ETA First US Port')"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'history']"
                        />
                      </b-button></label>
                      <b-form-datepicker
                        v-model="ShipmentDetail.ETAFirstUSPort"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="ATA First US Port"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class=""><i><b>ATA First US Port:</b></i><b-button
                        v-b-tooltip.hover="'View history'"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="history"
                        class="btn-icon"
                        size="sm"
                        @click="LoadHistory('ATA First US Port')"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'history']"
                        />
                      </b-button></label>
                      <b-form-datepicker
                        v-model="ShipmentDetail.ATAFirstUSPort"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="POD"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>POD:</b></i></label>
                      <v-select
                        v-model="ShipmentDetail.POD"
                        :options="POLPODOptions"
                        label="location"
                        placeholder="Select a Location"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="POD ETA"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>POD ETA:</b></i><b-button
                        v-b-tooltip.hover="'View history'"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="history"
                        class="btn-icon"
                        size="sm"
                        @click="LoadHistory('ETA POD')"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'history']"
                        />
                      </b-button></label>
                      <b-form-datepicker
                        v-model="ShipmentDetail.PODETA"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="POD ATA"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>POD ATA:</b></i><b-button
                        v-b-tooltip.hover="'View history'"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="history"
                        class="btn-icon"
                        size="sm"
                        @click="LoadHistory('ATA POD')"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'history']"
                        />
                      </b-button></label>
                      <b-form-datepicker
                        v-model="ShipmentDetail.PODATA"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Final Dest."
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Final Dest.:</b></i></label>
                      <v-select
                        v-model="ShipmentDetail.FinalDest"
                        :options="POLPODOptions"
                        label="location"
                        placeholder="Select a Location"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Final Dest. ETA"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Final Dest. ETA:</b></i><b-button
                        v-b-tooltip.hover="'View history'"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="history"
                        class="btn-icon"
                        size="sm"
                        @click="LoadHistory('ETA Final Dest')"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'history']"
                        />
                      </b-button></label>
                      <b-form-datepicker
                        v-model="ShipmentDetail.FinalDestETA"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Final Dest. ATA"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Final Dest. ATA:</b></i><b-button
                        v-b-tooltip.hover="'View history'"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="history"
                        class="btn-icon"
                        size="sm"
                        @click="LoadHistory('ATA Final Dest')"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'history']"
                        />
                      </b-button></label>
                      <b-form-datepicker
                        v-model="ShipmentDetail.FinalDestATA"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Commodity"
                    rules="max:100"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Commodity:</b></i></label>
                      <b-form-input
                        id="Commodity"
                        v-model="ShipmentDetail.Commodity"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Commodity"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Delivery Location"
                    rules="max:150"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Delivery Location:</b></i></label>
                      <b-form-input
                        id="DeliveryLocation"
                        v-model="ShipmentDetail.DeliveryLocation"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Delivery Location"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <app-collapse>
              <app-collapse-item title="Feeder Vessel">
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Vessel Name"
                        rules="max:50"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>Vessel Name:</b></i></label>
                          <b-form-input
                            v-model="ShipmentDetail.FeederVesselName"
                            v-uppercase
                            :state="errors.length > 0 ? false:null"
                            placeholder="Vessel Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Vessel Voyage"
                        rules="max:50"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>Vessel Voyage:</b></i></label>
                          <b-form-input
                            v-model="ShipmentDetail.FeederVoyage"
                            v-uppercase
                            :state="errors.length > 0 ? false:null"
                            placeholder="Vessel Voyage"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="ETD"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>ETD:</b></i><b-button
                            v-b-tooltip.hover="'View history'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="history"
                            class="btn-icon"
                            size="sm"
                            @click="LoadHistory('Feeder Vessel ETD')"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'history']"
                            />
                          </b-button></label>
                          <b-form-datepicker
                            v-model="ShipmentDetail.FeederETD"
                            placeholder="MM/DD/YYYY"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="en"
                            :reset-button="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="ATD"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>ATD:</b></i><b-button
                            v-b-tooltip.hover="'View history'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="history"
                            class="btn-icon"
                            size="sm"
                            @click="LoadHistory('Feeder Vessel ATD')"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'history']"
                            />
                          </b-button></label>
                          <b-form-datepicker
                            v-model="ShipmentDetail.FeederATD"
                            placeholder="MM/DD/YYYY"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="en"
                            :reset-button="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="ETA"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>ETA:</b></i><b-button
                            v-b-tooltip.hover="'View history'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="history"
                            class="btn-icon"
                            size="sm"
                            @click="LoadHistory('Feeder Vessel ETA')"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'history']"
                            />
                          </b-button></label>
                          <b-form-datepicker
                            v-model="ShipmentDetail.FeederETA"
                            placeholder="MM/DD/YYYY"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="en"
                            :reset-button="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="ATA"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>ATA:</b></i><b-button
                            v-b-tooltip.hover="'View history'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="history"
                            class="btn-icon"
                            size="sm"
                            @click="LoadHistory('Feeder Vessel ATA')"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'history']"
                            />
                          </b-button></label>
                          <b-form-datepicker
                            v-model="ShipmentDetail.FeederATA"
                            placeholder="MM/DD/YYYY"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="en"
                            :reset-button="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="F/V POL"
                        rules=""
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>F/V POL:</b></i></label>
                          <v-select
                            v-model="ShipmentDetail.FeederVIAPOL"
                            :options="POLPODOptions"
                            label="location"
                            placeholder="Select a Location"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="F/V POD"
                        rules=""
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>F/V POD:</b></i></label>
                          <v-select
                            v-model="ShipmentDetail.FeederVIAPOD"
                            :options="POLPODOptions"
                            label="location"
                            placeholder="Select a Location"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item title="Mother Vessel">
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Vessel Name"
                        rules="max:50|required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class="required"><i><b>Vessel Name:</b></i></label>
                          <b-form-input
                            v-model="ShipmentDetail.MotherVesselName"
                            v-uppercase
                            :state="errors.length > 0 ? false:null"
                            placeholder="Vessel Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Vessel Voyage"
                        rules="max:50|required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class="required"><i><b>Vessel Voyage:</b></i></label>
                          <b-form-input
                            v-model="ShipmentDetail.MotherVoyage"
                            v-uppercase
                            :state="errors.length > 0 ? false:null"
                            placeholder="Vessel Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="ETD"
                        rules="required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class="required"><i><b>ETD:</b></i><b-button
                            v-b-tooltip.hover="'View history'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="history"
                            class="btn-icon"
                            size="sm"
                            @click="LoadHistory('Mother Vessel ETD')"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'history']"
                            />
                          </b-button></label>
                          <b-form-datepicker
                            v-model="ShipmentDetail.MotherETD"
                            placeholder="MM/DD/YYYY"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="en"
                            :reset-button="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="ATD"
                        rules=""
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>ATD:</b></i><b-button
                            v-b-tooltip.hover="'View history'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="history"
                            class="btn-icon"
                            size="sm"
                            @click="LoadHistory('Mother Vessel ATD')"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'history']"
                            />
                          </b-button></label>
                          <b-form-datepicker
                            v-model="ShipmentDetail.MotherATD"
                            placeholder="MM/DD/YYYY"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="en"
                            :reset-button="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="ETA"
                        rules="required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class="required"><i><b>ETA:</b></i><b-button
                            v-b-tooltip.hover="'View history'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="history"
                            class="btn-icon"
                            size="sm"
                            @click="LoadHistory('Mother Vessel ETA')"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'history']"
                            />
                          </b-button></label>
                          <b-form-datepicker
                            v-model="ShipmentDetail.MotherETA"
                            placeholder="MM/DD/YYYY"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="en"
                            :reset-button="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="ATA"
                        rules=""
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class=""><i><b>ATA:</b></i><b-button
                            v-b-tooltip.hover="'View history'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="history"
                            class="btn-icon"
                            size="sm"
                            @click="LoadHistory('Mother Vessel ATA')"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'history']"
                            />
                          </b-button></label>
                          <b-form-datepicker
                            v-model="ShipmentDetail.MotherATA"
                            placeholder="MM/DD/YYYY"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="en"
                            :reset-button="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="M/V POL"
                        rules=""
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>M/V POL:</b></i></label>
                          <v-select
                            v-model="ShipmentDetail.MotherVIAPOL"
                            :options="POLPODOptions"
                            label="location"
                            placeholder="Select a Location"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="M/V POD"
                        rules=""
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>M/V POD:</b></i></label>
                          <v-select
                            v-model="ShipmentDetail.MotherVIAPOD"
                            :options="POLPODOptions"
                            label="location"
                            placeholder="Select a Location"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </validation-observer>
          <div class="divider my-2">
            <div class="divider-text">
              {{ 'Last Updated: ' + ShipmentDetail.UpdatedOn + ' by ' + ShipmentDetail.UpdatedBy + ' | Created: ' + ShipmentDetail.CreatedOn + ' by ' + ShipmentDetail.CreatedBy }}
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-tabs lazy>
            <b-tab
              ref="ContainerTab"
              title="Containers"
            >
              <ShipmentDetailContainer
                :key="RefreshKey"
                :shipment-id="ShipmentId"
                :detention-day="ShipmentDetail.DetentionDate"
                @LoadChildWeightCBM="LoadChildWeightCBM"
              />
            </b-tab>
            <b-tab
              v-if="CheckNullEmpty(ShipmentDetail.MBL)"
              ref="DocumentTab"
              title="Documents"
            >
              <ShipmentDetailDocuments
                :shipment-id="ShipmentId"
              />
            </b-tab>
            <b-tab
              ref="RemarkTab"
              title="Remark"
            >
              <ShipmentDetailRemark
                :shipment-id="ShipmentId"
              />
            </b-tab>
            <b-tab
              ref="TimelineTab"
              title="Timeline"
            >
              <ShipmentDetailTimeline
                :shipment-id="ShipmentId"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="DateHistoryModal"
      ref="DateHistory"
      title="Date History"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <b-row>
        <b-table
          v-if="ResponseTable"
          :fields="fields"
          :items="ResponseTable"
          responsive=""
          hover
        />
      </b-row>
      <template #modal-footer>
        <div /></template>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ShipmentDetailContainer from './ShipmentDetailContainer.vue'
import ShipmentDetailTimeline from './ShipmentDetailTimeline.vue'
import ShipmentDetailRemark from './ShipmentDetailRemark.vue'
import ShipmentDetailDocuments from './ShipmentDetailDocuments.vue'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  components: {
    ShipmentDetailContainer,
    ShipmentDetailTimeline,
    ShipmentDetailRemark,
    ShipmentDetailDocuments,
  },
  data() {
    return {
      fields: [
        { key: 'dateType', lable: 'Date Type' },
        { key: 'originalDate', lable: 'Original Date' },
        { key: 'updatedDate', lable: 'New Date' },
        { key: 'updatedBy', lable: 'Updated By' },
        { key: 'updatedOn', lable: 'Updated On' },
      ],
      ResponseTable: [],

      RefreshKey: 0,
      required,
      ShipmentId: this.$route.params.shipmentId,
      CustomerOptions: [],
      ConsigneeOptions: [],
      SellerOptions: [],
      BookingIdListOptions: [],
      ShipmentModeOptions: [
        { text: 'Air', value: 'Air' },
        { text: 'LCL', value: 'LCL' },
        { text: 'FCL', value: 'FCL' },
        { text: 'BCN', value: 'BCN' },
      ],
      ShipmentTypeOptions: [
        { text: 'FIX', value: 'FIX' },
        { text: 'SPOT', value: 'SPOT' },
        { text: 'EXX', value: 'EXX' },
        { text: 'SOC', value: 'SOC' },
        { text: 'PREMIUM', value: 'PREMIUM' },
        { text: 'LAND & SEA', value: 'LAND & SEA' },
        { text: 'AIR & SEA', value: 'AIR & SEA' },
      ],
      ServiceTypeOptions: [
        { text: 'CY/CY', value: 'CY/CY' },
        { text: 'CY/DOOR', value: 'CY/DOOR' },
        { text: 'CFS/CFS', value: 'CFS/CFS' },
        { text: 'CFS/DOOR', value: 'CFS/DOOR' },
        { text: 'CFS/CY', value: 'CFS/CY' },
        { text: 'CY/RAMP', value: 'CY/RAMP' },
      ],
      IncotermOptions: [
        { text: 'EXW', value: 'EXW' },
        { text: 'FCA', value: 'FCA' },
        { text: 'FAS', value: 'FAS' },
        { text: 'FOB', value: 'FOB' },
        { text: 'CFR', value: 'CFR' },
        { text: 'CIF', value: 'CIF' },
        { text: 'CPT', value: 'CPT' },
        { text: 'CIP', value: 'CIP' },
        { text: 'DAP', value: 'DAP' },
        { text: 'DAU', value: 'DAU' },
        { text: 'DDP', value: 'DDP' },
        { text: 'DDU', value: 'DDU' },
      ],
      ShipmentDetail: {
        CustomerId: '',
        Seller: '',
        SellerId: '',
        Consignee: '',
        ConsigneeId: '',
        Carrier: '',

        SelectedBookingIdList: '',
        BookingIdList: [],

        MBL: '',

        ShipmentMode: '',
        ShipmentType: '',
        ServiceType: '',
        Incoterm: '',

        IsNew: false,
        IsPendingReview: false,
        IsApproved: false,
        IsDenied: false,
        IsArchive: false,
        IsArrived: false,
        IsInTransit: false,
        IsPendingDepart: false,
        IsPendingLoading: false,
        IsPendingSO: false,
        IsComplete: false,
        IsDG: false,

        ApprovedDate: '',
        POL: '',
        POLName: '',
        CYCutoffDate: '',
        SICutoffDate: '',

        FeederVesselName: '',
        FeederVoyage: '',
        FeederETD: '',
        FeederATD: '',
        FeederETA: '',
        FeederATA: '',
        FeederVIAPOL: '',
        FeederVIAPOLName: '',
        FeederVIAPOD: '',
        FeederVIAPODName: '',

        MotherVesselName: '',
        MotherVoyage: '',
        MotherETD: '',
        MotherATD: '',
        MotherETA: '',
        MotherATA: '',
        MotherVIAPOL: '',
        MotherVIAPOLName: '',
        MotherVIAPOD: '',
        MotherVIAPODName: '',

        POD: '',
        PODName: '',
        PODETA: '',
        PODATA: '',

        TransitTime: 0,

        TerminalLocation: '',
        TerminalLocationName: '',

        FinalDest: '',
        FinalDestName: '',
        FinalDestETA: '',
        FinalDestATA: '',

        ETAFirstUSPort: '',
        ATAFirstUSPort: '',

        OBLTelexRcvDate: '',
        DetentionDay: 0,

        CBM: 0,
        Weight: 0,
        Pallet: 0,
        CTN: 0,
        PCS: 0,

        Commodity: '',

        DeliveryLocation: '',

        UpdatedBy: '',
        UpdatedOn: '',
        CreatedBy: '',
        CreatedOn: '',
      },
      minETDDate: '',
      minATDDate: '',
      minETAFirstPort: '',
      minATAFirstPort: '',
      OriginETAFirstPort: '',
      UpdatedETAFirstPort: '',
      ETAFirstPortDelay: 0,
      Permission: {},
      Role: '',
      ApprovedBookings: [],
      POLPODOptions: [],
      CarrierOptions: [],
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadCustomerList(), this.LoadPOLPODList(), this.LoadCarrierList()]).then(() => {
        this.LoadShipmentDetail().then(() => {
          Promise.all([this.LoadSellerList(), this.LoadConsigneeList(), this.LoadBookingList()]).then(() => {
            this.ShipmentDetail.SellerId = this.SellerOptions.find(option => option.id === this.ShipmentDetail.Seller)
            this.ShipmentDetail.ConsigneeId = this.ConsigneeOptions.find(option => option.id === this.ShipmentDetail.Consignee)
            try {
              const selectedvalue = this.ShipmentDetail.SelectedBookingIdList.split(',')
              selectedvalue.forEach(v => {
                if (this.CheckNullEmpty(v)) this.ShipmentDetail.BookingIdList.push(this.BookingIdListOptions.find(option => option.value === parseInt(v, 10)))
              })
            } catch (e) { this.ShipmentDetail.BookingIdList = [] }
            this.LoadETAFirstPort()
            this.LoadingEnd()
          })
        })
      })
    },
    LoadCarrierList() {
      return axios.get('/booking/carrier')
        .then(rowData => {
          this.CarrierOptions = rowData.data
        })
    },
    LoadPOLPODList() {
      // Loading Customer List
      return axios.get('/booking/codes/polpod')
        .then(rowData => {
          this.POLPODOptions = rowData.data
        })
    },
    LoadCustomerList() {
      // Loading Customer List
      return axios.get('/booking/customer/codes/all')
        .then(rowData => {
          this.CustomerOptions = rowData.data
        })
    },
    LoadSellerList() {
      // Loading Customer List
      return axios.get(`/booking/customer/codes/${this.ShipmentDetail.CustomerId.id}/seller`)
        .then(rowData => {
          this.SellerOptions = rowData.data
        })
    },
    LoadConsigneeList() {
      // Loading Customer List
      return axios.get(`/booking/customer/codes/${this.ShipmentDetail.CustomerId.id}/consignee`)
        .then(rowData => {
          this.ConsigneeOptions = rowData.data
        })
    },
    LoadBookingList() {
      // Loading Booking List
      return axios.get(`/booking/shipment/${this.ShipmentId}/${this.ShipmentDetail.CustomerId.id}/bookings/approved`)
        .then(rowData => {
          this.ApprovedBookings = rowData.data
          rowData.data.forEach(e => {
            this.BookingIdListOptions.push({ text: e.booking.bookingNumber, value: e.booking.bookingId })
          })
        })
    },
    LoadShipmentDetail() {
      return axios.get(`/booking/shipment/${this.ShipmentId}/detail`)
        .then(resp => {
          const response = resp.data
          const respdata = response

          this.ShipmentDetail.CustomerId = this.CustomerOptions.find(option => option.id === respdata.customerId)
          this.ShipmentDetail.Seller = respdata.sellerId
          this.ShipmentDetail.Consignee = respdata.consigneeId

          this.ShipmentDetail.MBL = respdata.mbl

          this.ShipmentDetail.ShipmentMode = this.ShipmentModeOptions.find(option => option.value === respdata.shipmentMode)
          this.ShipmentDetail.ShipmentType = this.ShipmentTypeOptions.find(option => option.value === respdata.shipmentType)
          this.ShipmentDetail.ServiceType = this.ServiceTypeOptions.find(option => option.value === respdata.serviceType)
          this.ShipmentDetail.Incoterm = this.IncotermOptions.find(option => option.value === respdata.incoterm)

          this.ShipmentDetail.IsNew = respdata.isNew
          this.ShipmentDetail.IsPendingReview = respdata.isPendingReview
          this.ShipmentDetail.IsApproved = respdata.isApproved
          this.ShipmentDetail.IsDenied = respdata.isDenied
          this.ShipmentDetail.IsArchive = respdata.isArchive
          this.ShipmentDetail.IsArrived = respdata.isArrived
          this.ShipmentDetail.IsInTransit = respdata.isInTransit
          this.ShipmentDetail.IsPendingDepart = respdata.isPendingDepart
          this.ShipmentDetail.IsPendingLoading = respdata.isPendingLoading
          this.ShipmentDetail.IsPendingSO = respdata.isPendingSO
          if (!this.ShipmentDetail.IsArrived
              && !this.ShipmentDetail.IsInTransit
              && !this.ShipmentDetail.IsPendingDepart
              && !this.ShipmentDetail.IsPendingLoading
              && !this.ShipmentDetail.IsPendingSO) {
            this.ShipmentDetail.IsComplete = true
          }
          this.ShipmentDetail.IsDG = respdata.isDG

          this.ShipmentDetail.SelectedBookingIdList = respdata.bookingIdList

          this.ShipmentDetail.Carrier = this.CarrierOptions.find(option => option.id === respdata.carrier)
          this.ShipmentDetail.DeliveryLocation = respdata.deliveryLocation
          this.ShipmentDetail.ApprovedDate = new Date(respdata.approvedDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.approvedDate) : null

          this.ShipmentDetail.POL = this.POLPODOptions.find(option => option.id === respdata.pol)
          this.ShipmentDetail.POLName = respdata.polName
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.CYCutoffDate = this.CheckNull(respdata.cyCutoffDate) ? new Date(respdata.cyCutoffDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.cyCutoffDate) : null : null
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.SICutoffDate = this.CheckNull(respdata.siCutoffDate) ? new Date(respdata.siCutoffDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.siCutoffDate) : null : null

          this.ShipmentDetail.FeederVesselName = respdata.feederVesselName
          this.ShipmentDetail.FeederVoyage = respdata.feederVoyage
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.FeederETD = this.CheckNull(respdata.feederETD) ? new Date(respdata.feederETD).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.feederETD) : null : null
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.FeederATD = this.CheckNull(respdata.feederATD) ? new Date(respdata.feederATD).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.feederATD) : null : null
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.FeederETA = this.CheckNull(respdata.feederETA) ? new Date(respdata.feederETA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.feederETA) : null : null
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.FeederATA = this.CheckNull(respdata.feederATA) ? new Date(respdata.feederATA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.feederATA) : null : null
          this.ShipmentDetail.FeederVIAPOD = this.POLPODOptions.find(option => option.id === respdata.feederVIAPOD)
          this.ShipmentDetail.FeederVIAPODName = respdata.feederVIAPODName
          this.ShipmentDetail.FeederVIAPOL = this.POLPODOptions.find(option => option.id === respdata.feederVIAPOL)
          this.ShipmentDetail.FeederVIAPOLName = respdata.feederVIAPOLName

          this.ShipmentDetail.MotherVesselName = respdata.motherVesselName
          this.ShipmentDetail.MotherVoyage = respdata.motherVoyage
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.MotherETD = this.CheckNull(respdata.motherETD) ? new Date(respdata.motherETD).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.motherETD) : null : null
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.MotherATD = this.CheckNull(respdata.motherATD) ? new Date(respdata.motherATD).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.motherATD) : null : null
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.MotherETA = this.CheckNull(respdata.motherETA) ? new Date(respdata.motherETA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.motherETA) : null : null
          this.ShipmentDetail.MotherVIAPOD = this.POLPODOptions.find(option => option.id === respdata.motherVIAPOD)
          this.ShipmentDetail.MotherVIAPOL = this.POLPODOptions.find(option => option.id === respdata.motherVIAPOL)

          this.ShipmentDetail.Service = respdata.service
          this.ShipmentDetail.POD = this.POLPODOptions.find(option => option.id === respdata.pod)
          this.ShipmentDetail.PODName = respdata.podName
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.PODETA = this.CheckNull(respdata.podeta) ? new Date(respdata.podeta).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.podeta) : null : null
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.PODATA = this.CheckNull(respdata.podata) ? new Date(respdata.podata).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.podata) : null : null
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.FinalDestETA = this.CheckNull(respdata.finalDestETA) ? new Date(respdata.finalDestETA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.finalDestETA) : null : null
          this.ShipmentDetail.TransitTime = respdata.transitTime
          this.ShipmentDetail.TerminalLocation = this.POLPODOptions.find(option => option.id === respdata.terminalLocation)
          this.ShipmentDetail.TerminalLocationName = respdata.terminalLocationName
          this.ShipmentDetail.FinalDest = this.POLPODOptions.find(option => option.id === respdata.finalDest)
          this.ShipmentDetail.FinalDestName = respdata.finalDestName

          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.FinalDestETA = this.CheckNull(respdata.finalDestETA) ? new Date(respdata.finalDestETA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.finalDestETA) : null : null
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.FinalDestATA = this.CheckNull(respdata.finalDestATA) ? new Date(respdata.finalDestATA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.finalDestATA) : null : null

          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.ETAFirstUSPort = this.CheckNull(respdata.etaFirstUSPort) ? new Date(respdata.etaFirstUSPort).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.etaFirstUSPort) : null : null
          // eslint-disable-next-line no-nested-ternary
          this.ShipmentDetail.ATAFirstUSPort = this.CheckNull(respdata.ataFirstUSPort) ? new Date(respdata.ataFirstUSPort).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.ataFirstUSPort) : null : null

          this.ShipmentDetail.DetentionDay = respdata.detentionDay

          this.ShipmentDetail.OblTelexRcvDate = new Date(respdata.oblTelexRcvDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.oblTelexRcvDate) : null

          this.ShipmentDetail.CBM = respdata.cbm
          this.ShipmentDetail.Weight = respdata.weight
          this.ShipmentDetail.Pallet = respdata.pallet
          this.ShipmentDetail.CTN = respdata.ctn
          this.ShipmentDetail.PCS = respdata.pcs

          this.ShipmentDetail.Commodity = respdata.commodity

          this.ShipmentDetail.DeliveryLocation = respdata.deliveryLocation

          this.ShipmentDetail.CreatedBy = respdata.createdBy
          this.ShipmentDetail.CreatedOn = respdata.createdOn
          this.ShipmentDetail.UpdatedBy = respdata.updatedBy
          this.ShipmentDetail.UpdatedOn = respdata.updatedOn

          // Get user role
          this.Role = this.GetUserRole()
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
        })
    },

    DeleteShipment() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/booking/shipment/${this.ShipmentId}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been removed.')
              .then(() => {
                // redirect
                this.$router.push('/booking/shipment')
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    ArchiveShipment() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, archive it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/shipment/${this.ShipmentId}/archive`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been archived.')
              .then(() => {
                this.LoadShipmentDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot archive shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    UnArchiveShipment() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, unarchive it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/shipment/${this.ShipmentId}/unarchive`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been unarchived.')
              .then(() => {
                this.LoadShipmentDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot unarchive shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    SubmitShipment() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/shipment/${this.ShipmentId}/submit`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been submitted.')
              .then(() => {
                this.LoadShipmentDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot submit Shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    WithdrawShipment() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, withdraw it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/shipment/${this.ShipmentId}/withdraw`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been withdrawn.')
              .then(() => {
                this.LoadShipmentDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot withdraw Shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    ApproveShipment() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, apporve it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/shipment/${this.ShipmentId}/approve`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been approved.')
              .then(() => {
                this.LoadShipmentDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot approve Shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    DenyShipment() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, deny it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/shipment/${this.ShipmentId}/deny`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been denied.')
              .then(() => {
                this.LoadShipmentDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot deny Shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    PendingSO() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/shipment/${this.ShipmentId}/status/pendingso`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been marked as "Pending SO".')
              .then(() => {
                this.LoadShipmentDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot update shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    PendingLoading() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/shipment/${this.ShipmentId}/status/pendingloading`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been marked as "Pending Loading".')
              .then(() => {
                this.LoadShipmentDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot update shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    PendingDepart() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/shipment/${this.ShipmentId}/status/pendingdepart`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been marked as "Pending Depart".')
              .then(() => {
                this.LoadShipmentDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot update shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    Depart() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()

          axios.put(`/booking/shipment/${this.ShipmentId}/status/depart`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been marked as "Depart".')
              .then(() => {
                this.LoadShipmentDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot update shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    Arrived() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/shipment/${this.ShipmentId}/status/arrived`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been marked as "Arrived".')
              .then(() => {
                this.LoadShipmentDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot update shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    Complete() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/shipment/${this.ShipmentId}/status/complete`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Shipment has been marked as "Complete".')
              .then(() => {
                this.LoadShipmentDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot update shipment.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    UpdateDetail() {
      this.$refs.DetailRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ShipmentModel = {
            MBL: this.ShipmentDetail.MBL,
            BookingIdList: this.ShipmentDetail.BookingIdList.length > 0 ? (this.ShipmentDetail.BookingIdList.map(a => a.value)).join() : null,
            IsDG: this.ShipmentDetail.IsDG,

            CustomerId: this.ShipmentDetail.CustomerId.id,
            ConsigneeId: this.CheckNull(this.ShipmentDetail.ConsigneeId) ? this.ShipmentDetail.ConsigneeId.id : null,
            SellerId: this.CheckNull(this.ShipmentDetail.SellerId) ? this.ShipmentDetail.SellerId.id : null,

            ShipmentMode: this.ShipmentDetail.ShipmentMode.value,
            ShipmentType: this.ShipmentDetail.ShipmentType.value,
            ServiceType: this.ShipmentDetail.ServiceType.value,
            Incoterm: this.ShipmentDetail.Incoterm.value,

            Carrier: this.ShipmentDetail.Carrier.id,
            TerminalLocation: this.CheckNull(this.ShipmentDetail.TerminalLocation) ? this.ShipmentDetail.TerminalLocation.id : null,
            DetentionDay: this.ShipmentDetail.DetentionDay,
            OblTelexRcvDate: this.CheckNull(this.ShipmentDetail.OblTelexRcvDate) ? new Date(this.ShipmentDetail.OblTelexRcvDate).toJSON().split('T')[0] : null,

            POL: this.ShipmentDetail.POL.id,
            SICutoffDate: this.CheckNull(this.ShipmentDetail.SICutoffDate) ? new Date(this.ShipmentDetail.SICutoffDate).toJSON().split('T')[0] : null,
            CYCutoffDate: this.CheckNull(this.ShipmentDetail.CYCutoffDate) ? new Date(this.ShipmentDetail.CYCutoffDate).toJSON().split('T')[0] : null,
            ETAFirstUSPort: this.CheckNull(this.ShipmentDetail.ETAFirstUSPort) ? new Date(this.ShipmentDetail.ETAFirstUSPort).toJSON().split('T')[0] : null,
            ATAFirstUSPort: this.CheckNull(this.ShipmentDetail.ATAFirstUSPort) ? new Date(this.ShipmentDetail.ATAFirstUSPort).toJSON().split('T')[0] : null,
            POD: this.ShipmentDetail.POD.id,
            PODETA: this.CheckNull(this.ShipmentDetail.PODETA) ? new Date(this.ShipmentDetail.PODETA).toJSON().split('T')[0] : null,
            PODATA: this.CheckNull(this.ShipmentDetail.PODATA) ? new Date(this.ShipmentDetail.PODATA).toJSON().split('T')[0] : null,

            FinalDest: this.ShipmentDetail.FinalDest.id,
            FinalDestETA: this.CheckNull(this.ShipmentDetail.FinalDestETA) ? new Date(this.ShipmentDetail.FinalDestETA).toJSON().split('T')[0] : null,
            FinalDestATA: this.CheckNull(this.ShipmentDetail.FinalDestATA) ? new Date(this.ShipmentDetail.FinalDestATA).toJSON().split('T')[0] : null,

            FeederVesselName: this.ShipmentDetail.FeederVesselName,
            FeederVoyage: this.ShipmentDetail.FeederVoyage,
            FeederETD: this.CheckNull(this.ShipmentDetail.FeederETD) ? new Date(this.ShipmentDetail.FeederETD).toJSON().split('T')[0] : null,
            FeederATD: this.CheckNull(this.ShipmentDetail.FeederATD) ? new Date(this.ShipmentDetail.FeederATD).toJSON().split('T')[0] : null,
            FeederETA: this.CheckNull(this.ShipmentDetail.FeederETA) ? new Date(this.ShipmentDetail.FeederETA).toJSON().split('T')[0] : null,
            FeederATA: this.CheckNull(this.ShipmentDetail.FeederATA) ? new Date(this.ShipmentDetail.FeederATA).toJSON().split('T')[0] : null,
            FeederVIAPOL: this.CheckNull(this.ShipmentDetail.FeederVIAPOL) ? this.ShipmentDetail.FeederVIAPOL.id : null,
            FeederVIAPOD: this.CheckNull(this.ShipmentDetail.FeederVIAPOD) ? this.ShipmentDetail.FeederVIAPOD.id : null,

            MotherVesselName: this.ShipmentDetail.MotherVesselName,
            MotherVoyage: this.ShipmentDetail.MotherVoyage,
            MotherATD: this.CheckNull(this.ShipmentDetail.MotherATD) ? new Date(this.ShipmentDetail.MotherATD).toJSON().split('T')[0] : null,
            MotherETD: this.CheckNull(this.ShipmentDetail.MotherETD) ? new Date(this.ShipmentDetail.MotherETD).toJSON().split('T')[0] : null,
            MotherATA: this.CheckNull(this.ShipmentDetail.MotherATA) ? new Date(this.ShipmentDetail.MotherATA).toJSON().split('T')[0] : null,
            MotherETA: this.CheckNull(this.ShipmentDetail.MotherETA) ? new Date(this.ShipmentDetail.MotherETA).toJSON().split('T')[0] : null,
            MotherVIAPOL: this.CheckNull(this.ShipmentDetail.MotherVIAPOL) ? this.ShipmentDetail.MotherVIAPOL.id : null,
            MotherVIAPOD: this.CheckNull(this.ShipmentDetail.MotherVIAPOD) ? this.ShipmentDetail.MotherVIAPOD.id : null,

            CBM: this.ShipmentDetail.CBM,
            Weight: this.ShipmentDetail.Weight,
            Pallet: this.ShipmentDetail.Pallet,
            CTN: this.ShipmentDetail.CTN,
            PCS: this.ShipmentDetail.PCS,

            Commodity: this.ShipmentDetail.Commodity,

            DeliveryLocation: this.ShipmentDetail.DeliveryLocation,
          }
          // Post
          axios.put(`/booking/shipment/${this.ShipmentId}/detail`, ShipmentModel)
            .then(() => {
              this.LoadingEnd()
              this.DisplaySuccess('Details have been saved.').then(() => {
                this.LoadShipmentDetail()
                this.LoadETAFirstPort()
                this.RefreshKey += 1
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.DisplayError('Details cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Details cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    LoadHistory(type) {
      let History = []
      axios.get(`/booking/shipment/${this.ShipmentId}/dates/history`)
        .then(resp => {
          History = resp.data
          this.ResponseTable = History.filter(c => c.dateType === type)
          this.$refs.DateHistory.show()
        })
    },
    LoadETAFirstPort() {
      axios.get(`/booking/shipment/${this.ShipmentId}/dates/history`)
        .then(resp => {
          const History = resp.data
          const dates = History.filter(c => c.dateType === 'ETA First US Port')
          try {
            this.OriginETAFirstPort = dates[0].updatedDate
            this.UpdatedETAFirstPort = dates[dates.length - 1].updatedDate
            const date1 = new Date(this.OriginETAFirstPort)
            const date2 = new Date(this.UpdatedETAFirstPort)
            if (date1.toJSON().split('T')[0] !== '1970-01-01' && date2.toJSON().split('T')[0] !== '1970-01-01') { // true = not null
              const dtime = date2.getTime() - date1.getTime()
              this.ETAFirstPortDelay = Math.round(dtime / (1000 * 3600 * 24))
            } else {
              this.ETAFirstPortDelay = 0
            }
            if (this.ETAFirstPortDelay >= 2) document.getElementById('ETAFirstPort').setAttribute('style', 'color:red')
            else if (this.ETAFirstPortDelay < 0) document.getElementById('ETAFirstPort').setAttribute('style', 'color:green')
          } catch (e) {
            //
          }
        })
    },
    onChangeSetBookingDetail(params) {
      let FirstBookingId = 0
      try {
        FirstBookingId = params[0].value
      } catch (e) { FirstBookingId = 0 }

      if (FirstBookingId !== 0) {
        // Find Booking
        const BKDetail = this.ApprovedBookings.find(c => c.booking.bookingId === FirstBookingId)
        // Set details
        // Set DB
        this.ShipmentDetail.IsDG = this.CheckNull(this.ShipmentDetail.IsDG) ? this.ShipmentDetail.IsDG : BKDetail.booking.isDG

        this.ShipmentDetail.Commodity = this.CheckNull(this.ShipmentDetail.Commodity) ? this.ShipmentDetail.Commodity : BKDetail.booking.commodity

        this.ShipmentDetail.DeliveryLocation = this.CheckNull(this.ShipmentDetail.DeliveryLocation) ? this.ShipmentDetail.DeliveryLocation : BKDetail.booking.deliveryLocation

        // Service Type, Service Mode, Shipment Mode
        this.ShipmentDetail.ShipmentMode = this.CheckNull(this.ShipmentDetail.ShipmentMode) ? this.ShipmentDetail.ShipmentMode : this.ShipmentModeOptions.find(option => option.value === BKDetail.booking.bookingMode)
        this.ShipmentDetail.ShipmentType = this.CheckNull(this.ShipmentDetail.ShipmentType) ? this.ShipmentDetail.ShipmentType : this.ShipmentTypeOptions.find(option => option.value === BKDetail.booking.bookingType)
        this.ShipmentDetail.ServiceType = this.CheckNull(this.ShipmentDetail.ServiceType) ? this.ShipmentDetail.ServiceType : this.ServiceTypeOptions.find(option => option.value === BKDetail.booking.serviceType)
        this.ShipmentDetail.Incoterm = this.CheckNull(this.ShipmentDetail.Incoterm) ? this.ShipmentDetail.Incoterm : this.IncotermOptions.find(option => option.value === BKDetail.booking.incoterm)

        // Consignee / shipper
        this.ShipmentDetail.SellerId = this.CheckNull(this.ShipmentDetail.SellerId) ? this.ShipmentDetail.SellerId : this.SellerOptions.find(option => option.id === BKDetail.booking.sellerId)
        this.ShipmentDetail.ConsigneeId = this.CheckNull(this.ShipmentDetail.ConsigneeId) ? this.ShipmentDetail.ConsigneeId : this.ConsigneeOptions.find(option => option.id === BKDetail.booking.consigneeId)

        // Carrier, Terminal, Detention day
        this.ShipmentDetail.Carrier = this.CheckNull(this.ShipmentDetail.Carrier) ? this.ShipmentDetail.Carrier : this.CarrierOptions.find(option => option.id === BKDetail.sailingSchedule.carrierId)
        this.ShipmentDetail.TerminalLocation = this.CheckNull(this.ShipmentDetail.TerminalLocation) ? this.ShipmentDetail.TerminalLocation : this.POLPODOptions.find(option => option.id === BKDetail.sailingSchedule.terminalLocation)
        this.ShipmentDetail.DetentionDay = this.CheckNull(this.ShipmentDetail.DetentionDay) ? this.ShipmentDetail.DetentionDay : BKDetail.booking.detentionDay

        // POL, POD, Final Dest, Delivery Location
        this.ShipmentDetail.POL = this.CheckNull(this.ShipmentDetail.POL) ? this.ShipmentDetail.POL : this.POLPODOptions.find(option => option.id === BKDetail.booking.pol)
        // eslint-disable-next-line no-nested-ternary
        this.ShipmentDetail.CYCutoffDate = this.CheckNull(this.ShipmentDetail.CYCutoffDate) ? this.ShipmentDetail.CYCutoffDate : this.CheckNull(BKDetail.sailingSchedule.cyCutoffDate) ? new Date(BKDetail.sailingSchedule.cyCutoffDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(BKDetail.sailingSchedule.cyCutoffDate) : null : null
        // eslint-disable-next-line no-nested-ternary
        this.ShipmentDetail.SICutoffDate = this.CheckNull(this.ShipmentDetail.SICutoffDate) ? this.ShipmentDetail.SICutoffDate : this.CheckNull(BKDetail.sailingSchedule.siCutoffDate) ? new Date(BKDetail.sailingSchedule.feederETA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(BKDetail.sailingSchedule.siCutoffDate) : null : null

        this.ShipmentDetail.POD = this.CheckNull(this.ShipmentDetail.POD) ? this.ShipmentDetail.POD : this.POLPODOptions.find(option => option.id === BKDetail.booking.pod)
        this.ShipmentDetail.PODETA = this.CheckNull(this.ShipmentDetail.PODETA) ? this.ShipmentDetail.PODETA : new Date(BKDetail.sailingSchedule.podeta)

        this.ShipmentDetail.FinalDest = this.CheckNull(this.ShipmentDetail.FinalDest) ? this.ShipmentDetail.FinalDest : this.POLPODOptions.find(option => option.id === BKDetail.booking.finalDest)
        this.ShipmentDetail.FinalDestETA = this.CheckNull(this.ShipmentDetail.FinalDestETA) ? this.ShipmentDetail.FinalDestETA : new Date(BKDetail.sailingSchedule.finalDestETA)

        // Feeder, Mother
        this.ShipmentDetail.FeederVesselName = this.CheckNull(this.ShipmentDetail.FeederVesselName) ? this.ShipmentDetail.FeederVesselName : BKDetail.sailingSchedule.feederVesselName
        this.ShipmentDetail.FeederVoyage = this.CheckNull(this.ShipmentDetail.FeederVoyage) ? this.ShipmentDetail.FeederVoyage : BKDetail.sailingSchedule.feederVoyage
        // eslint-disable-next-line no-nested-ternary
        this.ShipmentDetail.FeederETD = this.CheckNull(this.ShipmentDetail.FeederETD) ? this.ShipmentDetail.FeederETD : this.CheckNull(BKDetail.sailingSchedule.feederETD) ? new Date(BKDetail.sailingSchedule.feederETD).toJSON().split('T')[0] !== '1970-01-01' ? new Date(BKDetail.sailingSchedule.feederETD) : null : null
        // eslint-disable-next-line no-nested-ternary
        this.ShipmentDetail.FeederETA = this.CheckNull(this.ShipmentDetail.FeederETA) ? this.ShipmentDetail.FeederETA : this.CheckNull(BKDetail.sailingSchedule.feederETA) ? new Date(BKDetail.sailingSchedule.feederETA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(BKDetail.sailingSchedule.feederETA) : null : null
        this.ShipmentDetail.FeederVIAPOD = this.CheckNull(this.ShipmentDetail.FeederVIAPOD) ? this.ShipmentDetail.FeederVIAPOD : this.POLPODOptions.find(option => option.id === BKDetail.booking.feederVIAPOD)
        this.ShipmentDetail.FeederVIAPOL = this.CheckNull(this.ShipmentDetail.FeederVIAPOL) ? this.ShipmentDetail.FeederVIAPOL : this.POLPODOptions.find(option => option.id === BKDetail.booking.feederVIAPOL)

        this.ShipmentDetail.MotherVesselName = this.CheckNull(this.ShipmentDetail.FeederVesselName) ? this.ShipmentDetail.FeederVesselName : BKDetail.sailingSchedule.motherVesselName
        this.ShipmentDetail.MotherVoyage = this.CheckNull(this.ShipmentDetail.FeederVoyage) ? this.ShipmentDetail.FeederVoyage : BKDetail.sailingSchedule.motherVoyage
        // eslint-disable-next-line no-nested-ternary
        this.ShipmentDetail.MotherETD = this.CheckNull(this.ShipmentDetail.MotherETD) ? this.ShipmentDetail.MotherETD : this.CheckNull(BKDetail.sailingSchedule.motherETD) ? new Date(BKDetail.sailingSchedule.motherETD).toJSON().split('T')[0] !== '1970-01-01' ? new Date(BKDetail.sailingSchedule.motherETD) : null : null
        // eslint-disable-next-line no-nested-ternary
        this.ShipmentDetail.MotherETA = this.CheckNull(this.ShipmentDetail.MotherETA) ? this.ShipmentDetail.MotherETA : this.CheckNull(BKDetail.sailingSchedule.motherETA) ? new Date(BKDetail.sailingSchedule.motherETA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(BKDetail.sailingSchedule.motherETA) : null : null
        this.ShipmentDetail.MotherVIAPOD = this.CheckNull(this.ShipmentDetail.MotherVIAPOD) ? this.ShipmentDetail.MotherVIAPOD : this.POLPODOptions.find(option => option.id === BKDetail.booking.motherVIAPOD)
        this.ShipmentDetail.MotherVIAPOL = this.CheckNull(this.ShipmentDetail.MotherVIAPOL) ? this.ShipmentDetail.MotherVIAPOL : this.POLPODOptions.find(option => option.id === BKDetail.booking.motherVIAPOL)
      }
    },
    onChangeSetConsigneeSeller() {
      if (this.CheckNull(this.ShipmentDetail.CustomerId)) {
        this.ShipmentDetail.SellerId = ''
        this.ShipmentDetail.ConsigneeId = ''
        this.LoadSellerList()
        this.LoadConsigneeList()
      }
    },
    LoadPDF() {
      const dd = {
        pageOrientation: 'portrait',
        pageSize: 'LETTER',
        content: [],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
          },
          table: {
            margin: [0, 5, 0, 15],
          },
          tableHeader: {
            bold: true,
            fontSize: 11,
            color: 'black',
          },
          tbtitle: {
            bold: true,
            italics: true,
            fontSize: 11,
            color: 'black',
          },
        },
        defaultStyle: {
          // alignment: 'justify'
        },
      }
      const ShipmentDetail = [
        { text: 'Shipment Detail', style: 'subheader' },
        {
          style: 'table',
          color: '#444',
          table: {
            widths: ['auto', 'auto'],
            body: [
              [{ text: 'MBL #:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.MBL}` }],
              [{ text: 'HBL #:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.HBL || ''}` }],
              [{ text: 'Customer:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.CustomerId.name || ''}` }],
              [{ text: 'Service Type:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.ShipmentType.value || ''}` }],
              [{ text: 'Service Mode:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.ServiceType.value || ''}` }],
              [{ text: 'DG:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.IsDG ? 'Yes' : 'No' || 'No'}` }],
              [{ text: 'Booking #:', style: 'tbtitle', alignment: 'center' }, { text: `${(this.ShipmentDetail.BookingIdList.map(a => a.text)).join() || ''}` }],
              [{ text: 'Total CBM:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.CBM || ''}` }],
              [{ text: 'Total Weight (KG):', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.Weight || ''}` }],
              [{ text: 'Total Pallets:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.Pallet || ''}` }],
            ],
          },
        },
        // Routing
        {
          style: 'table',
          color: '#444',
          table: {
            widths: ['auto', 'auto'],
            body: [
              [{ }, { text: 'Mother Vessel' }],
              [{ text: 'Carrier:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.Carrier || ''}` }],
              [{ text: 'Vessel Name:/Voyage', style: 'tbtitle', alignment: 'center' }, { text: `${`${this.ShipmentDetail.MotherVesselName || ''} / ${this.ShipmentDetail.MotherVoyage || ''}` || ''}` }],
              [{ text: 'Vessel ETD:', style: 'tbtitle', alignment: 'center' }, { text: `${new Date(this.ShipmentDetail.MotherETD).toJSON().split('T')[0] || ''}` }],
              [{ text: 'Vessel ATD:', style: 'tbtitle', alignment: 'center' }, { text: `${new Date(this.ShipmentDetail.MotherATD).toJSON().split('T')[0] || ''}` }],
              [{ text: 'ETA First Port:', style: 'tbtitle', alignment: 'center' }, { text: `${new Date(this.ShipmentDetail.ETAFirstPort).toJSON().split('T')[0] || ''}` }],
              [{ text: 'ETA Final Destination:', style: 'tbtitle', alignment: 'center' }, { text: `${new Date(this.ShipmentDetail.ETAFinalDest).toJSON().split('T')[0] || ''}` }],
              [{ text: 'ATA First Port:', style: 'tbtitle', alignment: 'center' }, { text: `${new Date(this.ShipmentDetail.ATAFirstPort).toJSON().split('T')[0] || ''}` }],
              [{ text: 'ATA Final Destination:', style: 'tbtitle', alignment: 'center' }, { text: `${new Date(this.ShipmentDetail.ATAFinalDest).toJSON().split('T')[0] || ''}` }],
              [{ text: 'Port of Loading:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.POL.location || ''}` }],
              [{ text: 'Port of Discharge:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.POD.location || ''}` }],
              [{ text: 'Destination:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.FinalDest || ''}` }],
              [{ text: 'Detention Days:', style: 'tbtitle', alignment: 'center' }, { text: `${this.ShipmentDetail.DetentionDate || ''}` }],
            ],
          },
        },
        // Conatiners
      ]
      dd.content.push(ShipmentDetail)

      return dd
    },
    Print() {
      const doc = this.LoadPDF()
      const win = window.open('', '_blank')
      pdfMake.createPdf(doc).open({}, win)
    },
    LoadChildWeightCBM(v) {
      this.ShipmentDetail.CBM = v.CBM
      this.ShipmentDetail.Weight = v.Weight
      this.ShipmentDetail.Pallet = v.Pallet
      this.ShipmentDetail.CTN = v.CTN
      this.ShipmentDetail.PCS = v.PCS
    },
  },
}
</script>
