<template>
  <div>
    <b-card>
      <b-row>
        <b-table
          v-if="ResponseTable"
          :fields="fields"
          :items="ResponseTable"
          responsive=""
          hover
        />
      </b-row>
      <b-row>
        <b-col md="11">
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="Message"
              placeholder="Enter your message"
            />
          </b-input-group>
        </b-col>
        <b-col md="1">
          <b-button
            variant="primary"
            @click="SaveNewRemark()"
          >
            Send
          </b-button>
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'

export default {
  props: {
    shipmentId: { type: String, default: '' },
  },
  data() {
    return {
      required,
      fields: [
        { key: 'remark', lable: 'Message' },
        { key: 'addedByName', lable: 'Added By' },
        { key: 'addedOn', lable: 'Added On' },
      ],
      ResponseTable: [],

      RefreshKey: 0,
      Message: '',
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadShipmentRemark()]).then(() => {
        this.LoadingEnd()
      })
    },
    LoadShipmentRemark() {
      return axios.get(`/booking/shipment/${this.shipmentId}/remarks`)
        .then(resp => {
          this.ResponseTable = resp.data
        })
    },
    SaveNewRemark() {
      if (this.Message !== '') {
        this.LoadingStart()
        const ShipmentRemarksModel = {
          Remark: this.Message,
        }

        axios.post(`/booking/shipment/${this.shipmentId}/remarks`, ShipmentRemarksModel)
          .then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Remark has been added.').then(() => {
              this.Message = ''
              this.LoadShipmentRemark()
            })
          })
          .catch(e => {
            this.LoadingEnd()
            this.DisplayError('New remark cannot be added.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
      } else {
        this.DisplayError('New remark cannot be saved.', 'Please check data requirements.')
      }
    },
  },

}
</script>
