var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('AgGrid',{ref:"ShipmentDetailContainerGrid",attrs:{"grid":_vm.aggrid},on:{"LoadAll":_vm.LoadAll},scopedSlots:_vm._u([{key:"lftbtn",fn:function(){return [((!_vm.ShipmentDetail.IsArchive && (_vm.Role != 'readonly' && _vm.Role != 'readonly admin') && (_vm.Permission.EditShipment)) || (_vm.Role == 'company admin'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Add new containers'),expression:"'Add new containers'",modifiers:{"hover":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.MassUpdateContainer()}}},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":['far', 'edit'],"size":"lg"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Mass Update")])],1):_vm._e()]},proxy:true}])})],1),_c('b-modal',{ref:"MassUpdate",attrs:{"id":"MassUpdateModal","title":"Mass Update Container","size":"lg","centered":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.MassUpdateSave()}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Save")])])],1)]},proxy:true}])},[_c('validation-observer',{ref:"MassUpdateRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Last Free Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Last Free Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.MassUpdate.LastFreeDate),callback:function ($$v) {_vm.$set(_vm.MassUpdate, "LastFreeDate", $$v)},expression:"MassUpdate.LastFreeDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Empty Dispatch Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Empty Dispatch Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.MassUpdate.DispatchDate),callback:function ($$v) {_vm.$set(_vm.MassUpdate, "DispatchDate", $$v)},expression:"MassUpdate.DispatchDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Gate In Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Gate In Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.MassUpdate.InGateDate),callback:function ($$v) {_vm.$set(_vm.MassUpdate, "InGateDate", $$v)},expression:"MassUpdate.InGateDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Gate Out Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Gate Out Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.MassUpdate.OutGateDate),callback:function ($$v) {_vm.$set(_vm.MassUpdate, "OutGateDate", $$v)},expression:"MassUpdate.OutGateDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Discharge Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Discharge Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.MassUpdate.DischargedDate),callback:function ($$v) {_vm.$set(_vm.MassUpdate, "DischargedDate", $$v)},expression:"MassUpdate.DischargedDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Appointment Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Appointment Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.MassUpdate.AppointmentDate),callback:function ($$v) {_vm.$set(_vm.MassUpdate, "AppointmentDate", $$v)},expression:"MassUpdate.AppointmentDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Delivery Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Delivery Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.MassUpdate.DeliveredDate),callback:function ($$v) {_vm.$set(_vm.MassUpdate, "DeliveredDate", $$v)},expression:"MassUpdate.DeliveredDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Empty Return Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Empty Return Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.MassUpdate.EmptyReturnDate),callback:function ($$v) {_vm.$set(_vm.MassUpdate, "EmptyReturnDate", $$v)},expression:"MassUpdate.EmptyReturnDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1),_c('b-modal',{ref:"UpdateContainer",attrs:{"id":"UpdateContainerModal","title":"Update Container","size":"lg","centered":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.UpdateContainer()}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Save")])])],1)]},proxy:true}])},[_c('validation-observer',{ref:"UpdateContainerRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Container Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Container Type:")])])]),_c('v-select',{attrs:{"options":_vm.ContainerTypeOption,"label":"text","placeholder":"Select the type"},model:{value:(_vm.container.ContainerSize),callback:function ($$v) {_vm.$set(_vm.container, "ContainerSize", $$v)},expression:"container.ContainerSize"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Container Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Container Number:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Container Number"},model:{value:(_vm.container.ContainerNumber),callback:function ($$v) {_vm.$set(_vm.container, "ContainerNumber", $$v)},expression:"container.ContainerNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Per Diem Free Time (days)","rules":"positive|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Per Diem Free Time (days):")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"1","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.PerdiemFreeTime),callback:function ($$v) {_vm.$set(_vm.container, "PerdiemFreeTime", $$v)},expression:"container.PerdiemFreeTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Last Free Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Last Free Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.container.LastFreeDate),callback:function ($$v) {_vm.$set(_vm.container, "LastFreeDate", $$v)},expression:"container.LastFreeDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Dispatch Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Empty Dispatch Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.container.DispatchDate),callback:function ($$v) {_vm.$set(_vm.container, "DispatchDate", $$v)},expression:"container.DispatchDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Gate In Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Gate In Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.container.InGateDate),callback:function ($$v) {_vm.$set(_vm.container, "InGateDate", $$v)},expression:"container.InGateDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Gate Out Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Gate Out Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},on:{"input":function($event){return _vm.PerDIEM()}},model:{value:(_vm.container.OutGateDate),callback:function ($$v) {_vm.$set(_vm.container, "OutGateDate", $$v)},expression:"container.OutGateDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Discharge Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Discharge Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.container.DischargedDate),callback:function ($$v) {_vm.$set(_vm.container, "DischargedDate", $$v)},expression:"container.DischargedDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Appointment Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Appointment Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.container.AppointmentDate),callback:function ($$v) {_vm.$set(_vm.container, "AppointmentDate", $$v)},expression:"container.AppointmentDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Delivery Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Delivery Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},model:{value:(_vm.container.DeliveredDate),callback:function ($$v) {_vm.$set(_vm.container, "DeliveredDate", $$v)},expression:"container.DeliveredDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Empty Return Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Empty Return Date:")])])]),_c('b-form-datepicker',{attrs:{"placeholder":"MM/DD/YYYY","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"en"},on:{"input":function($event){return _vm.PerDIEM()}},model:{value:(_vm.container.EmptyReturnDate),callback:function ($$v) {_vm.$set(_vm.container, "EmptyReturnDate", $$v)},expression:"container.EmptyReturnDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CBM","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("CBM:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.CBM),callback:function ($$v) {_vm.$set(_vm.container, "CBM", $$v)},expression:"container.CBM"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Weight (KG)","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Weight (KG):")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.Weight),callback:function ($$v) {_vm.$set(_vm.container, "Weight", $$v)},expression:"container.Weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Pallet","rules":"min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Pallet:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"1","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.Pallet),callback:function ($$v) {_vm.$set(_vm.container, "Pallet", $$v)},expression:"container.Pallet"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CTN","rules":"min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("CTN:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.CTN),callback:function ($$v) {_vm.$set(_vm.container, "CTN", $$v)},expression:"container.CTN"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"PCS","rules":"min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("PCS:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.PCS),callback:function ($$v) {_vm.$set(_vm.container, "PCS", $$v)},expression:"container.PCS"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[(_vm.Role != 'customer')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Ocean Freight Cost","rules":"required|min_value:0|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Ocean Freight Cost:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.OceanFreightCost),callback:function ($$v) {_vm.$set(_vm.container, "OceanFreightCost", $$v)},expression:"container.OceanFreightCost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1035989141)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Ocean Freight Sell Rate","rules":"required|min_value:0|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Ocean Freight Sell Rate:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.OceanFreightSellRate),callback:function ($$v) {_vm.$set(_vm.container, "OceanFreightSellRate", $$v)},expression:"container.OceanFreightSellRate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),(_vm.Role === 'manager' || _vm.Role === 'company admin')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Origin Sell Rate","rules":"min_value:0|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Origin Sell Rate:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.OriginSellRate),callback:function ($$v) {_vm.$set(_vm.container, "OriginSellRate", $$v)},expression:"container.OriginSellRate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,851239962)})],1)],1):_vm._e()],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" "+_vm._s('Last Updated: ' + _vm.container.UpdatedOn + ' by ' + _vm.container.UpdatedBy)+" ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }