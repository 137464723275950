<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="12">
          <validation-observer
            ref="UploadRules"
            tag="form"
          >
            <b-row>
              <b-col md="2">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Doc Type"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                        id="DocType"
                        v-model="DocType"
                        :options="DocTypeOptions"
                        label="text"
                        placeholder="Select a type"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="9">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Doc"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <b-form-file
                        ref="file"
                        v-model="Doc"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>

              </b-col>
              <b-col md="1">
                <b-button
                  v-if="(!ShipmentDetail.IsArchive && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditShipment)) || (Role == 'company admin')"
                  v-b-tooltip.hover="'Upload'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mb-1"
                  @click="UploadFile()"
                >
                  <font-awesome-icon
                    :icon="['fas', 'file-upload']"
                    size="lg"
                  /></b-button>&nbsp;
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>
      <AgGrid
        ref="ShipmentDocumentsGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <p><i>Required Docs (Uploaded / Not uploaded): </i><span style="color:green">{{ RequiredDocsUploaded }}</span> / <span style="color:red">{{ RequiredDocs }}</span></p>
          <p><i>Optional Docs (Uploaded / Not uploaded): </i><span style="color:green">{{ OptionalDocsUploaded }}</span> / <span style="color:red">{{ OptionalDocs }}</span></p>

        </template>

      </AgGrid>
    </b-card>

  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/Booking/BookingShipmentDocumentBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  name: 'ShipmentDocument',
  components: {
    AgGrid,
  },
  props: {
    shipmentId: { type: String, default: '' },
  },
  data() {
    return {
      required,
      aggrid: [],
      Permission: {},
      Role: '',
      OptionalDocs: '',
      OptionalDocsUploaded: '',
      RequiredDocs: '',
      RequiredDocsUploaded: '',
      ShipmentDetail: {
        MBL: '',
        IsNew: false,
        IsPendingReview: false,
        IsApproved: false,
        IsDenied: false,
        IsArchive: false,
        IsArrived: false,
        IsInTransit: false,
        IsPendingDepart: false,
        IsPendingLoading: false,
        IsPendingSO: false,
      },
      DocTypeOptions: [
        { text: 'MBL', value: 'MBL' },
        { text: 'HBL', value: 'HBL' },
        { text: 'Profit Share', value: 'Profit Share' },
        { text: 'Debit Note', value: 'Debit Note' },
        { text: 'AMS', value: 'AMS' },
        { text: 'ISF', value: 'ISF' },
        { text: 'CI', value: 'CI' },
        { text: 'PL', value: 'PL' },
        { text: 'Manifest', value: 'Manifest' },
        { text: 'Load Plan', value: 'Load Plan' },
        { text: 'Consignee Confirmation', value: 'Consignee Confirmation' },
        { text: 'Origin Cost', value: 'Origin Cost' },

      ],
      DocType: '',
      Doc: [],
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.aggrid = this.InitGridInstance('ShipmentDocuments')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'id',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      {
        headerName: 'Type', field: 'docType', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Doc Name',
        field: 'docName',
        minWidth: 180,
      },
      { headerName: 'Added By', field: 'addedBy', minWidth: 100 },
      { headerName: 'Added On', field: 'addedOn', minWidth: 100 },

    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ShipmentDocumentsGrid.saveState('ShipmentDocuments') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ShipmentDocumentsGrid.saveState('ShipmentDocuments') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ShipmentDocumentsGrid.saveState('ShipmentDocuments') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ShipmentDocumentsGrid.saveState('ShipmentDocuments') }

    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadShipmentDetail()]).then(() => {
        this.LoadDocumentList()
        this.LoadingEnd()
      })
    },
    LoadShipmentDetail() {
      return axios.get(`/booking/shipment/${this.shipmentId}/detail`)
        .then(resp => {
          const response = resp.data
          const respdata = response
          this.ShipmentDetail.MBL = respdata.mbl
          this.ShipmentDetail.IsNew = respdata.isNew
          this.ShipmentDetail.IsPendingReview = respdata.isPendingReview
          this.ShipmentDetail.IsApproved = respdata.isApproved
          this.ShipmentDetail.IsDenied = respdata.isDenied
          this.ShipmentDetail.IsArchive = respdata.isArchive
          this.ShipmentDetail.IsArrived = respdata.isArrived
          this.ShipmentDetail.IsInTransit = respdata.isInTransit
          this.ShipmentDetail.IsPendingDepart = respdata.isPendingDepart
          this.ShipmentDetail.IsPendingLoading = respdata.isPendingLoading
          this.ShipmentDetail.IsPendingSO = respdata.isPendingSO
          // Get user role
          this.Role = this.GetUserRole()
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
        })
    },
    LoadDocumentList() {
      axios.get(`/booking/shipment/${this.ShipmentDetail.MBL}/documents`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
          try {
            // eslint-disable-next-line prefer-destructuring
            this.RequiredDocs = rowData.data[0].requiredDocsUploaded.split('^')[1]
            // eslint-disable-next-line prefer-destructuring
            this.RequiredDocsUploaded = rowData.data[0].requiredDocsUploaded.split('^')[0] === '' ? 'None' : rowData.data[0].requiredDocsUploaded.split('^')[0]
          } catch {
            this.RequiredDocs = 'MBL/HBL/Profit Share/Debit Note'
            this.RequiredDocsUploaded = 'None'
          }
          try {
            // eslint-disable-next-line prefer-destructuring
            this.OptionalDocs = rowData.data[0].optionalDocsUploaded.split('^')[1]
            // eslint-disable-next-line prefer-destructuring
            this.OptionalDocsUploaded = rowData.data[0].optionalDocsUploaded.split('^')[0] === '' ? 'None' : rowData.data[0].optionalDocsUploaded.split('^')[0]
          } catch {
            this.OptionalDocs = 'AMS/ISF/CI/PL/Manifest/Load Plan'
            this.OptionalDocsUploaded = 'None'
          }
        }).then(() => {
          this.$refs.ShipmentDocumentsGrid.ResetColumns()
        })
    },
    UploadFile() {
      this.$refs.UploadRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const formData = new FormData()
          formData.append('file', this.Doc)
          formData.append('MBL', this.ShipmentDetail.MBL)
          formData.append('DocType', this.DocType.value)
          formData.append('DocName', this.Doc.name)
          axios.post(`/booking/shipment/${this.ShipmentDetail.MBL}/documents/upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(() => {
              this.LoadingEnd()
              this.DisplaySuccess('Document has been uploaded.').then(() => {
                this.LoadDocumentList()
                this.DocType = ''
                this.Doc = null
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.DisplayError('Document cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Cannot upload file.', 'Please check data requirements.')
        }
      })
    },
    Download(DocumentId, FileName) {
      axios.get(`/booking/shipment/${this.ShipmentDetail.MBL}/documents/${DocumentId}/download`, {
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', FileName)
        link.click()
      })
    },
    Delete(documentId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/booking/shipment/${this.ShipmentDetail.MBL}/documents/${documentId}/delete`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Document has been removed.').then(() => {
              this.LoadDocumentList()
            })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Document cannot be removed.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
  },
}
</script>
